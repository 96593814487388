import React from 'react';
import {makeStyles} from "@mui/styles";
import {Box, Checkbox, Grid, Tooltip, Typography, Divider} from "@mui/material";
import {
	Archive as ArticleIcon
} from "@mui/icons-material";
import {VideoPreview} from "../../../../../components";
import {convertNewlinesToBreaks} from "../../../../../helper/convertor";

const RecommendationList = (props) => {
	const {
		data,
		enabledRecommendations,
		onOpenDialogImagesMessage,
		onEditEnabledRecommendations,
		onOpenDialogImagesVideos,
		isFilesLoading
	} = props;
	const classes = useStyles();

	if (data.length < 1) {
		return (
			<Grid className={classes.root} container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h3" textAlign="center">Нет рекомендаций</Typography>
				</Grid>
			</Grid>
		);
	}

	return (
		<Grid className={classes.root} container spacing={2}>
			<Grid item xs={12}>
				<Typography variant="h3" textAlign="left">Перечень рекомендаций от LRDRIVE</Typography>
			</Grid>

			{data.length && data.map((item) => (
				<Grid key={`recommendation-${item.id}`} item xs={12} lg={6}>
					<RecommendationCard
						item={item}
						isEnabled={enabledRecommendations.includes(item.id)}
						onEditEnabledRecommendations={onEditEnabledRecommendations}
						onOpenDialogImagesVideos={onOpenDialogImagesVideos}
						onOpenDialogImagesMessage={onOpenDialogImagesMessage}
						isFilesLoading={isFilesLoading}
					/>
				</Grid>
			))}
		</Grid>
	);
};
const RecommendationCard = (props) => {
	const {
		item,
		isEnabled,
		onEditEnabledRecommendations,
		onOpenDialogImagesVideos,
		onOpenDialogImagesMessage
	} = props;
	const cost = Number.parseFloat(item.cost);
	const isNotConfirmed = Boolean(item.confirmedAt === null);

	const [items, setItems] = React.useState(() => {
		let adminNote = String(item?.admin_note || "").split("<br>");
		return [...(item?.items || [])]
			.sort((a,b) => {
				if (a.id > b.id) {
					return 1
				}
				if (a.id < b.id) {
					return -1
				}
				return 0
			})
			.map((_item, index) => {
				return {
					..._item,
					adminNote: adminNote[index] || "",
					images: [...(item.pictures || [])]
						.filter((_picture) => Boolean((_item?.images || []).find((x) => x.id === _picture.id)))
						.map((t) => {
							return {
								...t,
								url: t?.url
							}
						}),
				}
			})
	});

	const classes = useStylesCard();

	const handleChangeEnable = () => {
		onEditEnabledRecommendations(item.id);
	};
	const handleOpenDialogImagesMessage = (index) => {
		const pictures = (item.pictures || [])
			.filter((t) => {
				return !Boolean(['application/pdf'].includes(t.mimetype))
			});

		onOpenDialogImagesMessage({
			index,
			files: pictures,
			message: item?.admin_note || item?.value || ""
		})
	}
	const handleOpenDialogImagesMessageItems = (images, index, message) => {
		const pictures = (images || [])
			.filter((t) => {
				return !Boolean(['application/pdf'].includes(t.mimetype))
			});
		if (pictures.length !== images.length) {
			index = 0;
		}

		onOpenDialogImagesMessage({
			index,
			files: pictures || [],
			message: message || ""
		})
	}

	return (
		<Box className={classes.card}>
			<Box className={classes.cardHead}>
				<div className={classes.cardHeadTitle}>Рекомендация</div>
				<Tooltip arrow title={Boolean(!isNotConfirmed && !cost) ? 'Стоимость отсутствует, согласование невозможно' : ''}>
					<div>
						<Checkbox
							size="small"
							checked={isEnabled}
							disabled={isNotConfirmed || !cost}
							className={classes.cardHeadCheckbox}
							onChange={handleChangeEnable}
						/>
					</div>
				</Tooltip>
			</Box>
			<Box className={classes.cardBody}>
				<Grid container spacing={2}>
					{items.map((_item) => (
						<>
							<Grid item xs={12}>
								<div
									className={classes.cardMessage}
									dangerouslySetInnerHTML={{__html: _item.adminNote || _item.description}}
								/>
								{Boolean((_item.images || []).length > 0) && (
									<Grid container spacing={1}>
										{(_item.images || []).map((file, index) => {
											const isPdf = Boolean((file?.file?.type || file?.mimetype || "").includes("pdf"));
											if (isPdf) {
												return (
													<Box
														sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: 64 }}
														component="a"
														href={file.url}
														target="_blank"
													>
														<ArticleIcon sx={{ color: "#FF3F3F" }}/>
													</Box>
												)
											}

											const isVideo = Boolean((file?.mimetype || '').includes("video"));
											if (!!isVideo) {
												return (
													<Grid item xs={1} key={`video-file-${index + 1}`}>
														<VideoPreview
															file={file}
															onClick={handleOpenDialogImagesMessage.bind(this, index)}
															_onClick={onOpenDialogImagesVideos.bind(this, item.pictures || [], index)}
														/>
													</Grid>
												)
											}
											return (
												<Grid item xs={1} key={`photo-file-${index + 1}`}>
													<img
														className={classes.cardImage}
														onClick={handleOpenDialogImagesMessageItems.bind(this, _item.images, index, _item.adminNote)}
														src={String(file?.url || '')}
													/>
												</Grid>
											)
										})}
									</Grid>
								)}
							</Grid>
							<Grid item xs={12}>
								<Divider sx={{ borderColor: "rgba(21, 36, 53, 0.1)" }}/>
							</Grid>
						</>
					))}
				</Grid>
			</Box>
		</Box>
	);
};

const useStyles = makeStyles(() => ({
	root: {},
	prices: {

		"@media(max-width: 1199px)": {
			position: "sticky",
			bottom: 0,
			left: 0,
			backgroundColor: "white",
			borderRadius: "8px 8px 0 0",
			padding: 12,
			boxShadow: "0px 2px 8px rgb(142 150 168 / 24%)",
			marginLeft: 16,
			marginTop: 8,

			"& .MuiTypography-root": {
				display: "flex",
				flexDirection: "row",
				flewWrap: "nowrap",

				fontSize: 14,
				lineHeight: "14px",
				whiteSpace: "nowrap"
			},
			"& .MuiGrid-root > *": {
				width: "100%",

				"& span": {
					display: "block",
					flex: 1,
					borderBottom: "1px dotted #647083",
					margin: "0 4px"
				}
			}
		}
	},

	orderCard: {
		display: "flex",
		flexDirection: "column",
		height: "100%",

		padding: 20,
		boxSizing: "border-box",
		boxShadow: "0px 2px 8px rgba(142, 150, 168, 0.24)",
		backgroundColor: "white",
		borderRadius: 4,

		"@media(max-width: 1199px)": {
			padding: 12,
		}
	},
	orderCardHead: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		marginBottom: 12
	},
	orderCardBody: {
		marginBottom: 16
	},
	orderCardFooter: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-end",
		justifyContent: "flex-end",
		marginTop: "auto",

		"& > *": {
			marginTop: "4px"
		}
	},
	orderCardName: {
		fontSize: 20,
		lineHeight: "24px",
		color: "black",
		fontWeight: "500",

		"@media(max-width: 1199px)": {
			fontSize: 18,
			lineHeight: "20px",
		}
	},

	orderCardEstimateTitle: {
		fontSize: 16,
		lineHeight: "18px",
		marginBottom: 6
	},
	orderCardEstimate: {
		fontSize: 14,
		lineHeight: "18px",

		marginBottom: 20
	},

	filesTitle: {
		fontSize: 16,
		lineHeight: "18px",
		marginBottom: 6
	},
	files: {
		"& > * > *": {
			width: 60,
			height: 60,
			borderRadius: 4,
			overflow: "hidden",
			border: "1px solid red",
			objectFit: "contain"
		}
	},
	orderPriceBig: {
		fontSize: 20,
		lineHeight: "20px",
		color: "rgba(0,0,0,0.7)",

		"& span": {
			color: "black"
		}
	},
	orderPriceSmall: {
		fontSize: 16,
		lineHeight: "18px",
		color: "rgba(0,0,0,0.7)",

		"& span": {
			color: "black"
		}
	},

	rowContent: {
		width: "100%",
		display: "flex",
		gap: 10,
	},

	columnContent: {
		display: "flex",
		flexDirection: "column",
	},
	warning: {
		color: 'red',
		marginTop: '5px',
		fontSize: '14px'
	}
}));
const useStylesCard = makeStyles(() => ({
	card: {
		background: "#F4F6F8",
		borderRadius: 10,
		padding: "16px 20px",
		boxSizing: "border-box"
	},
	cardHead: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		marginBottom: 16,
		paddingBottom: 16,
		borderBottom: "1px solid rgba(21, 36, 53, 0.1)",
	},
	cardHeadTitle: {
		fontWeight: "600",
		fontSize: "20px",
		lineHeight: "120%",
		letterSpacing: "-0.02em",
		color: "#152435",
	},
	cardHeadCheckbox: {
		padding: 0
	},
	cardBody: {},
	cardMessage: {
		marginBottom: 10,

		fontWeight: "500",
		fontSize: "14px",
		lineHeight: "120%",
		letterSpacing: "-0.02em",
		color: "#152435"
	},
	cardImages: {},
	cardImage: {
		borderRadius: 4,
		width: "100%",
		height: "100%",
		objectFit: "cover"
	}
}));

export default RecommendationList;
